export default [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/auth/login.vue"),
    meta: {
      title: "Login",

    },
  },
  {
    path: "/logout",
    name: "logout",
    meta: {
      title: "Logout",
      authRequired: false,
      beforeResolve(routeTo, routeFrom, next) {
        localStorage.clear();
        sessionStorage.clear();
        next();
      },
    },
    component: () => import("../views/auth/logout/basic")
  },

  {
    path: "/dashboard",
    alias: "/",
    name: "dashboard",
    meta: {
      title: "Dashboard",
      authRequired: true,
    },
    component: () => import("../views/dashboard/index"),
  },

  {
    path: "/user/users",
    alias: ["/user", "/users"],
    name: "users",
    meta: {
      title: "Danh sách người dùng",
      authRequired: true,
    },
    component: () => import("../views/user/users"),
  },


  {
    path: "/user/collabs",
    alias: ["/collab", "/collabs"],
    name: "collabs",
    meta: {
      title: "Danh sách Cộng tác viên",
      authRequired: true,
    },
    component: () => import("../views/user/collabs"),
  },
  {
    path: "/user/credits",
    name: "credits",
    meta: {
      title: "Danh sách vay vốn",
      authRequired: true,
    },
    component: () => import("../views/user/credits"),
  },
  {
    path: "/userdetail/:id",
    name: "userdetail",
    meta: {
      title: "Danh sách người dùng",
      authRequired: true,
    },
    component: () => import("../views/user/userdetail"),
  },
  {
    path: "/user/insurance",
    name: "insurance",
    meta: {
      title: "Hợp đồng bảo hiểm",
      authRequired: true,
    },
    component: () => import("../views/user/insurance"),
  },
  {
    path: "/admin/userpermissions",
    name: "userpermissions",
    meta: {
      title: "Phân quyền người dùng",
      authRequired: true,
    },
    component: () => import("../views/admin/userpermissions"),
  },
  {
    path: "/admin/managers",
    name: "managers",
    meta: {
      title: "Danh sách quản trị viên",
      authRequired: true,
    },
    component: () => import("../views/admin/managers"),
  },
  {
    path: "/taxi/",
    name: "taxilist",
    meta: {
      title: "Danh sách tài xế taxi",
      authRequired: true,
    },
    component: () => import("../views/taxi/taxilist"),
  },
  {
    path: "/taxi/taxiconfig",
    name: "taxiconfig",
    meta: {
      title: "Cấu hình",
      authRequired: true,
    },
    component: () => import("../views/taxi/taxiconfig"),
  },
  {
    path: "/findxu",
    name: "findxu",
    meta: {
      title: "FindXu",
      authRequired: true,
    },
    component: () => import("../views/findxu/index"),
  },


  {
    path: "/store",
    name: "store",
    meta: {
      title: "Cửa hàng",
      authRequired: true,
    },
    component: () => import("../views/store/storeTabs"),
  },
  {
    path: "/store/affiliate",
    name: "storeaffiliate",
    meta: {
      title: "Cửa hàng liên kết",
      authRequired: true,
    },
    component: () => import("../views/store/storeTabs"),
  },
  {
    path: "/store/:id",
    name: "storedetail",
    meta: {
      title: "Chi tiết cửa hàng",
      authRequired: true,
    },
    component: () => import("../views/store/storedetail"),
  },
  {
    path: "/add-package/:id",
    name: "addpackage",
    meta: {
      title: "Thêm gói dịch vụ",
      authRequired: true,
    },
    component: () => import("../views/store/servicePack/addAdsPack"),
  },
  {
    path: "/order/",
    name: "orders",
    meta: {
      title: "Đơn hàng",
      authRequired: true,
    },
    component: () => import("../views/store/order"),
  },
  {
    path: "/order/:id",
    name: "orderdetail",
    meta: {
      title: "Chi tiết đơn hàng",
      authRequired: true,
    },
    component: () => import("../views/store/orderdetail"),
  },
  {
    path: "/transactions/refunds",
    name: "refunds",
    meta: {
      title: "Trả hàng hoàn đơn",
      authRequired: true,
    },
    component: () => import("../views/store/transactions"),
  },
  {
    path: "/transactions/complaints",
    name: "complaints",
    meta: {
      title: "Danh sách khiếu nại",
      authRequired: true,
    },
    component: () => import("../views/store/transactions"),
  },
  {
    path: "/refund-order/:id",
    name: "refundOrderDetail",
    meta: {
      title: "Chi tiết đơn hoàn trả",
      authRequired: true,
    },
    component: () => import("../views/store/refundOrder/refundOrderDetail"),
  },
  {
    path: "/complaint/:id",
    name: "complaint",
    meta: {
      title: "Chi tiết đơn khiếu nại",
      authRequired: true,
    },
    component: () => import("../views/store/complaints/complaintDetail"),
  },
  {
    path: "/productdetail/:id",
    name: "productdetail",
    meta: {
      title: "Chi tiết sản phẩm",
      authRequired: true,
    },
    component: () => import("../views/store/productdetail"),
  },
  {
    path: "/banner",
    name: "banner",
    meta: {
      title: "Thiết lập Banner",
      authRequired: true,
    },
    component: () => import("../views/config/banner"),
  },
  {
    path: "/news",
    name: "news",
    meta: {
      title: "Cổng thông tin",
      authRequired: true,
    },
    component: () => import("../views/news/news"),
  },

  {
    path: "/news/create",
    name: "newscreate",
    meta: {
      title: "Cổng thông tin",
      authRequired: true,
    },
    component: () => import("../views/news/detail"),
  },
  {
    path: "/news/:id",
    name: "newsdetail",
    meta: {
      title: "Cổng thông tin",
      authRequired: true,
    },
    component: () => import("../views/news/detail"),
  },
  {
    path: "/ads",
    name: "ads",
    meta: {
      title: "Quảng cáo",
      authRequired: true,
    },
    component: () => import("../views/ads/index"),
  },
  {
    path: "/ads/banners",
    name: "adsbanner",
    meta: {
      title: "Banner",
      authRequired: true,
    },
    component: () => import("../views/ads/index"),
  },
  {
    path: "/posts",
    name: "posts",
    meta: {
      title: "Bài viết",
      authRequired: true,
    },
    component: () => import("../views/post/postList/index"),
  },
  {
    path: "/violating/posts",
    name: "violatingPost",
    meta: {
      title: "Bài viết vi phạm",
      authRequired: true,
    },
    component: () => import("../views/post/postList/index"),
  },
  {
    path: "/schedule/posts",
    name: "schedulePost",
    meta: {
      title: "Danh sách lên lịch bài viết",
      authRequired: true,
    },
    component: () => import("../views/post/schedulePost/index"),
  },
  {
    path: "/adspackages",
    name: "adspackages",
    meta: {
      title: "Gói quảng cáo",
      authRequired: true,
    },
    component: () => import("../views/adspackages/index"),
  },
  {
    path: "/adseven",
    name: "adseven",
    meta: {
      title: "danh sách thông báo",
      authRequired: true,
    },
    component: () => import("../views/adseven/index"),
  },


  {
    path: "/config/store/services",
    name: "configstoreservices",
    meta: {
      title: "Thiết lập Danh sách dịch vụ",
      authRequired: true,
    },
    component: () => import("../views/config/store/services"),
  },
  {
    path: "/config/driver/cancelreason",
    name: "configdrivercancelreason",
    meta: {
      title: "Thiết lập Lý do hủy chuyến của lái xe",
      authRequired: true,
    },
    component: () => import("../views/config/taxi/cancelReasonTabs.vue"),
  },
  {
    path: "/config/passenger/cancelreason",
    name: "configbookingpersoncancelreason",
    meta: {
      title: "Thiết lập Lý do hủy chuyến của người đặt",
      authRequired: true,
    },
    component: () => import("../views/config/taxi/cancelReasonTabs.vue"),
  },
  {
    path: "/config/social/violationreason",
    name: "configsocialviolationreason",
    meta: {
      title: "Thiết lập Lý do vi phạm",
      authRequired: true,
    },
    component: () => import("../views/config/social/violationreason"),
  },
  {
    path: "/config/area/area",
    name: "configareaarea",
    meta: {
      title: "Thiết lập Khu vực",
      authRequired: true,
    },
    component: () => import("../views/config/area/area"),
  },
  {
    path: "/config/store/fields",
    name: "configstorefields",
    meta: {
      title: "Thiết lập Danh sách ngành hàng",
      authRequired: true,
    },
    component: () => import("../views/config/store/fields"),
  },
  {
    path: "/config/video-upload",
    name: "video-upload",
    meta: {
      title: "Tải lên Video",
      authRequired: true,
    },
    component: () => import("../views/config/UploadVideo"),
  }
];